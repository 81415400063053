<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'products', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Producten</span></router-link></li>
            <li>
                <router-link :to="{ name: 'product-details', params: { 
                    settlementId: settlement ? settlement.id : '0',
                    productId: product ? product.id : '0' 
                }}">
                    <span class="is-size-6">Product</span>
                </router-link>
            </li>
            <!-- <li><span class="is-size-6">Product</span></li> -->
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Producten {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <h2 class="is-size-6">Beheer uw product</h2>
        </div>

        <div v-if="product" class="product-details">
            <div class="box shadow m-4">
                <h1 class="has-text-primary is-size-5">Details</h1>
                <hr>
                <form>
                    <label for="name" class="has-text-primary is-size-6 pl-4">Product</label>
                    <input v-model="product.name" type="text" id="name" name="name" placeholder="Productnaam" class="input is-rounded">
                    <label for="price" class="has-text-primary is-size-6 pl-4">Prijs (incl. BTW)</label>
                    <input v-model="product.price" type="text" id="price" name="price" placeholder="Prijs" class="input is-rounded" @input="fixPriceFormat">
                    <label for="btwLevelId" class="has-text-primary is-size-6 pl-4">BTW type</label><br>
                    <div class="select is-rounded">
                        <select id="btwLevelId" name="btwLevelId" v-model="product.btwLevelId">
                            <option v-for="taxLevel in taxLevels" :value="taxLevel.id" :key="taxLevel.id">
                                {{ taxLevel.displayName + " (" + taxLevel.percentage + "%)" }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>

            <div class="box shadow m-4">
                <h1 class="has-text-primary is-size-5">Categorieën</h1>
                <hr>
                <form>
                    <div v-for="category in categories" :key="category.id">
                        <label class="checkbox is-size-6" >
                            <input type="checkbox" id="{{category.id}}" value="{{category.id}}" v-model="category.checked">
                            {{category.name}}
                        </label>
                        <br>
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="box shadow m-4">
            <!-- TODO: Nicer loading screen -->
            <h1>Loading...</h1>
        </div>

        <button class="button is-primary floating-action" @click="save">
            <span class="icon">
                <i class="fas fa-save"></i>
            </span>
        </button>
    </div>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import DI from '../../modules/di/DI.js';

export default {
    name: "ProductDetailsPage",
    components: {
    },
    data () {
        return {
            settlement: null,
            product: null,
            categories : [],
            taxLevels: []
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    // watch: {
    //     '$route.params.settlementId' : function (settlementId) {
    //         if (settlementId != null) {
    //             this.load()
    //         } else {
    //             console.log('nani, don\'t load')
    //             console.log(settlementId)
    //         }
    //     }
    // },
    async created () {
        this.load()
    },
    methods: {
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getProductById', {
                        id: this.getProductId()
                    }
                )

                var taxLevelsResponse = await DI.api.getTaxLevels();
                this.taxLevels = taxLevelsResponse.data
                

                var response = await DI.api.getCategories(this.settlement.id);
                var allCategories = response.data
                var mappedCategories = {};
                for (var i = 0; i < allCategories.length; i++) {
                    var category = allCategories[i];
                    category['checked'] = false
                    category['idx'] = null
                    mappedCategories[category.id] = category
                }

                this.product = this.$store.state.product.product

                for (var j = 0; j < this.product.categories.length; j++) {
                    var selected = this.product.categories[j];
                    mappedCategories[selected.id].checked = true
                    mappedCategories[selected.id].idx = selected.idx
                }
                this.categories = Object.values(mappedCategories)
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.settlementId
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        getProductId () {
            return this.$route.params.productId
        },
        fixPriceFormat () {
            return this.product.price = ("" + this.product.price).replace(",", ".")
        },
        async save () {
            // Format the categories in the way the API expects:
            var selectedCategories = this.categories.filter(category => {
                return category.checked === true
            })
            var productDTO = {
                name: this.product.name,
                price: parseFloat(this.product.price),
                btwLevelId: this.product.btwLevelId,
                categories: selectedCategories,
                settlementId: this.product.settlementId
            }

            try {
                await DI.api.updateProduct(this.getProductId(), productDTO)
                // Re-direct to the overview:
                this.$router.push('/settlement/' + this.settlement.id + '/products');
                await this.$store.dispatch('addNotification', {
                    message: 'Uw wijzigingen aan "' + this.product.name + '" zijn opgeslagen.'
                });
            } catch (e) {
                await this.$store.dispatch('addNotification', {
                    message: "Er ging iets mis bij het opslaan. Controleer uw invoer en probeer het opnieuw."
                })
            }
        }
    }
};
</script>

<style type="text/css" scoped>

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .product-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .product-details {
            display: grid;
            grid-template-columns: auto;
        }
    }
</style>