import axios from 'axios';
import l from '../lang/Translation.js'

var api = function(config, client) {

    let login = (username, password) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + '/api/auth/login', {
                username: username,
                password: password,
                client_id: config.oauth2.clientId,
                client_secret: config.oauth2.clientSecret,
                grant_type: 'password'
            }).then((response) => {
                resolve(response)
            }).catch((e) => {
                if (e.response.data.error) {
                    console.log(l.t(e.response.data.error))
                    reject(l.t(e.response.data.error))
                }
                reject('something_went_wrong')
            });
        })
    }

    let getProfile = () => {
        return client.get('/api/company/me')
    }

    let getStatistics = (settlementId) => {
        return client.get('/api/settlement/' + settlementId + '/statistics')
    }

    let getRevenue = (settlementId, startDate, endDate) => {
        return client.get('/api/settlement/' + settlementId + '/revenue?startDate=' + startDate + '&endDate=' + endDate)
    }

    let getDateRevenue = (settlementId, date) => {
        return client.get('/api/orders/date/' + date)
    }

    let getOrder = (orderId) => {
        return client.get('/api/orders/' + orderId)
    }
    
    let getProducts = (settlementId) => {
        return client.get('/api/' + settlementId + '/categorized-products');
    }

    let getProductById = (productId) => {
        return client.get('/api/product/' + productId);
    }

    let deleteProduct = (productId) => {
        return client.delete('/api/product/' + productId);
    }

    let getCategories = (settlementId) => {
        return client.get('/api/settlement/' + settlementId + '/categories');
    }

    let getTaxLevels = () => {
        return client.get('/api/tax-levels');
    }

    let createProduct = (productDTO) => {
        return client.post('/api/products', productDTO);
    }

    let updateProduct = (productId, productDTO) => {
        return client.put('/api/products/' + productId, productDTO);
    }

    // Expose API methods:
    return {
        login: login,
        getProfile: getProfile,
        getStatistics: getStatistics,
        getRevenue: getRevenue,
        getDateRevenue: getDateRevenue,
        getProducts: getProducts,
        deleteProduct: deleteProduct,
        getProductById: getProductById,
        getCategories: getCategories,
        getTaxLevels: getTaxLevels,
        createProduct: createProduct,
        updateProduct: updateProduct,
        getOrder: getOrder
    }

}

export default api