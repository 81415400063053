<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'products', params: { id: settlement ? settlement.id : '0' }}" class="router-link-active router-link-exact-active"><span class="is-size-6">Producten</span></router-link></li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Producten {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <h2 class="is-size-6">Beheer uw producten</h2>
        </div>

        <!-- Table for desktop -->
        <div v-if="overview.length > 0">
            <template v-for="category in overview" :key="category">
                <div class="box shadow m-4 mt-5 overview is-hidden-mobile">
                    <h1 class="has-text-primary is-size-5">{{ category.name }}</h1><span></span>
                    <hr><hr>
                    <div class="has-text-primary overview-row ">Product</div>
                    <div class="has-text-primary overview-row has-text-right">Prijs</div>
                    <template v-for="row in category.products" :key="row">
                        <div class="overview-row">
                            <router-link :to="{ name: 'product-details', params: { settlementId: settlement ? settlement.id : '0', productId: row.id }}">
                                <span class="is-size-6">{{ row.name }}</span>
                            </router-link>
                        </div>
                        <div class="overview-row has-text-right">
                            € {{ formatter.format(row.price, 2, ',', '.') }}
                            
                            <span class="icon pointer" v-on:click="openDeleteProduct(row)">
                                <i class="fas fa-trash"></i>
                            </span>
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <!-- Table for mobile devices -->
<!--         <div v-if="overview.length > 0" class="overview is-hidden-tablet">
            <template v-for="row in overview" :key="row">
                <div class="overview-row is-hidden-tablet">
                    <p class="ml-4 mr-4 mt-1 mb-1 is-italic has-text-left is-size-7">{{ row.day }}</p>
                    <div class="grid-3 ml-4 mr-4">
                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Pin</p>
                            <p>€ {{ formatter.format(row.pin, 2, ',', '.') }}</p>
                        </div>

                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Cash</p>
                            <p>€ {{ formatter.format(row.cash, 2, ',', '.') }}</p>
                        </div>
                        
                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Totaal</p>
                            <p>€ {{ formatter.format(row.pin + row.cash, 2, ',', '.') }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div> -->

        <!-- Delete product modal -->
        <Modal v-show="selectedProduct != null" @close="closeDeletePopup">
            <template v-slot:content>
                <h1>
                    Weet je zeker dat je "<span class="has-text-primary">{{ selectedProduct ? selectedProduct.name : '' }}</span>" wilt verwijderen? 
                    Dit verwijderd het product uit <span class="has-text-primary">ALLE</span> actieve categorieën.
                </h1>
                <div class="modal-buttons">
                    <button class="button is-size-6 is-primary has-text-weight-bold" v-on:click="deleteProduct">Ja</button>
                    <button class="button is-size-6 has-text-weight-bold has-text-primary">Nee</button>
                </div>
            </template>
        </Modal>

    </div>
    <button class="button is-primary floating-action" @click="addProduct">+</button>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import Formatter from '../../plugins/Formatter.js';
import Modal from '../core/Modal.vue';
import DI from '../../modules/di/DI.js';

export default {
    name: "ProductsPage",
    components: {
        Modal
    },
    data () {
        return {
            formatter: Formatter,
            settlement: null,
            overview: [],
            selectedProduct: null
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    watch: {
        '$route.params.settlementId' : function (id) {
            if (id) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        closeDeletePopup () {
            this.selectedProduct = null;
        },
        openDeleteProduct (product) {
            this.selectedProduct = product;
        },
        async deleteProduct () {
            await DI.api.deleteProduct(this.selectedProduct.id);
            await this.load()
            this.selectedProduct = null;
        },
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getProducts', {
                        settlementId: this.settlement.id
                    }
                )
                var categoryMap = {};
                // TODO: Define list of non-categorized products?

                for (var i = 0; i < this.$store.state.product.categorizedList.length; i++) {
                    var product = this.$store.state.product.categorizedList[i];
                    // Add the product to the category map:
                    for (var j = 0; j < product.categories.length; j++) {
                        var category = product.categories[j];
                        if(categoryMap[category.id] == null) {
                            categoryMap[category.id] = {
                                "id": category.id,
                                "name": category.name,
                                "products": {}
                            };
                        } 
                        categoryMap[category.id].products[category.idx] = product;
                    }
                }
                categoryMap = Object.values(categoryMap)
                this.overview = categoryMap
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.settlementId
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        addProduct () {
            this.$router.push('/settlement/' + this.settlement.id + '/products/create');
        }
    }
};
</script>

<style type="text/css" scoped>

    .overview {
        display: grid;
        grid-template-columns: auto auto;
    }

    .overview-row a {
        color: var(--dark-grey) !important;
    }

    .overview-row a:hover, .overview-row a:active {
        color: var(--primary) !important;
    }

    .grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 10px;
    }

    .overview-row {
        padding: 10px;
        text-align: left;
    }

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .grid-3 > .box {
        margin-bottom: 0;
    }

    .pointer {
        cursor: pointer;
    }


    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .overview {
            display: grid;
            grid-template-columns: auto;
        }
    }

/*    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }*/

</style>